.past-editions-container {
  background-color: black;
}
.info-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-container ul {
  list-style: none;
}

.info-container ul li {
  color: white;
  font-size: 24px;
}

.custom-title {
  color: #fff;
  font-size: 30px;
  text-align: center;
  margin: 20px;
}

.btn-container {
  margin: 30px 0;
}

@media screen and (max-width: 992px) {
  .custom-title {
    color: #fff;
    font-size: 22px;
    text-align: center;
    margin: 20px;
  }
}