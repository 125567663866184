@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;800;900&display=swap");

body {
  font-family: "Montserrat";
}

iframe {
  width: 400px;
  height: 50px;
  margin: 0 auto;
  background-color: #000;
}

iframe {
  display: block;
  border-style: none;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  height: auto;
}
.custom-collapse {
  width: 30px;
}

.main-container {
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../src/images/ltdf_bg.jpg");
}

.App-header .main-container .separator {
  height: 200px;
}

.route-container {
  width: 700px;
  height: 400px;
}

.App-header .main-container .logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.App-header .main-container .logo-text {
  font-size: 35px;
  color: #61a60e;
}
.App-header .main-container .logo-container .main-logo {
  width: 170px;
}

.App-header .main-container .logo-container .secondary-logo {
  width: 250px;
}

.App-header .main-container .description-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.App-header .main-container .description-text .date-text {
  font-weight: 800;
  font-size: 32px;
  color: #fff;
  margin: 0;
}

.App-header .main-container .description-text .place-text {
  margin: 0;
  background: black;
  color: white;
  padding: 10px;
  font-size: 12px;
  font-weight: 900;
  margin-top: 8px;
}

.App-header .main-container .social-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.App-header .main-container .social-text p {
  margin: 0;
  color: #fff;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 10px;
}
.App-header .main-container .social-text .social-logo {
  width: 30px;
}

.social-logo {
  width: 20px;
}

@media screen and (max-width: 992px) {
  .App-header .main-container .logo-container .main-logo {
    width: 150px;
  }

  .App-header .main-container .logo-container .secondary-logo {
    width: 300px;
  }
  .App-header .main-text {
    font-size: 22px;
  }
  .contact-container a {
    font-size: 15px;
  }

  iframe {
    width: 350px !important;
    height: 400px !important;
  }
}
